import Err404 from '@/pages/err404';
import PersonalDataErrorPage from '@/pages/privacy/personal-data-error';
import {PersonalDataRootPage} from '@/pages/privacy/personal-data-root';
import PersonalDataUpdatePage from '@/pages/privacy/personal-data-update';
import RootPrivacy from '@/root-privacy';

const routes = [
    {
        path: '/',
        element: <RootPrivacy />,
        children: [
            {
                path: '*',
                element: <Err404 />
            },
            {
                path: '/',
                element: <PersonalDataRootPage />,
            },
            {
                path: '/personal-data-update',
                element: <PersonalDataUpdatePage />,
            },
            {
                path: '/personal-data-error',
                element: <PersonalDataErrorPage />,
            },
        ]
    }];

export default routes;
