import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';

import PatientChildForm from '@/components/business/common/patient/patient-child-form';
import {SuccessMessage} from '@/components/form/success-message';
import {BackendApiService} from '@/services/backend-api-service';

class PrivacyUpdatePersonalDataChild extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        patientData: PropTypes.object.isRequired,
        onSaved: PropTypes.func,
        onChanged: PropTypes.func,
    };

    state = {
        loading: false,
        successMessage: '',
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <h3>{t(['Child information', 'updateChild.title'])}</h3>
                {state.successMessage &&
                    <SuccessMessage successMessage={state.successMessage}/>
                }
                <PatientChildForm patient={props.patientData} hideCheckboxes={true} loading={state.loading} onChanged={this.handleFormChanged} onSubmit={(personalData) => this.updatePatientPersonalData(personalData)} />
            </div>
        );
    }

    handleFormChanged(notSavedDataChild) {
        if(this.props.onChanged) {
            this.props.onChanged(notSavedDataChild);
        }
    }

    updatePatientPersonalData(personalData) {
        const { t } = this.props;
        
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'privacy',
            modelName: 'privacyUpdatePersonalDataChild',
            data: {
                patient: personalData
            }
        }).then((personalData) => {
            this.setState(state => ({
                ...state,
                loading: false,
                successMessage: t('updateChild.updateSuccess'),
            }));
            if(this.props.onSaved) {
                this.props.onSaved();
            }
            return personalData;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }
}

PrivacyUpdatePersonalDataChild = withTranslation()(PrivacyUpdatePersonalDataChild);
export {PrivacyUpdatePersonalDataChild};
