import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet} from 'react-router-dom';

import MainTopbarSimple from '@/components/layout/main-topbar-simple';
import LoadingView from '@/components/static/loading-view';
import OutdatedBrowserView from '@/components/static/outdated-browser-view';
import {FirestoreTransportService} from '@/services/api-transport/firestore-transport-service';
import {MockTransportService} from '@/services/api-transport/mock-transport-service';
import {Auth0Service} from '@/services/auth0-service';
import {BackendApiService} from '@/services/backend-api-service';
import isBrowserSupported from '@/util/browser-support';

import './style.js';

// Display Gabi Environment and define Transport Service
console.log(`Gabi environment: "${__GABI_ENV__}"`);

if (__GABI_ENV__ === 'testing')  {
    BackendApiService.setTransportService(new MockTransportService());
}
else {
    BackendApiService.setTransportService(new FirestoreTransportService());
}

// Root component, including common components surrounding active route
export default function RootPrivacy() {
    const { t } = useTranslation();
    const [ loading, setLoading ] = useState(true);
    
    useEffect(() => {
        (async () => {
            const urlParams = document.location.search.substring(1);
            if (urlParams.split('=')[0] === 'token') {
                const token = urlParams.split('=')[1];
                Auth0Service.setAccessTokenProvider(async () => {
                    return token;
                });
            }
            setLoading(false);
        })();
    }, []);

    if (!isBrowserSupported()) {
        return <OutdatedBrowserView/>;
    }
    else if (loading) {
        return <LoadingView/>;
    }
    else {
        return (
            <div>
                <MainTopbarSimple title={t('privacy.globalTitle')} />

                <main className="main-content">
                    <Outlet/>
                </main>
            </div>
        );
    }
}
