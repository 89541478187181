import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import ErrorBoundary from '@/components/errors/error-boundary';
import {InfoMessage} from '@/components/form/info-message';
import invalidToken from '@/public/images/invalid-token.png';
import {colorPalette} from '@/themes/darkmode';

class PersonalDataErrorPage extends React.Component {
    static propTypes = {
        t: PropTypes.func,
        className: PropTypes.string,
    };

    render() {
        const props = this.props;
        const { t } = this.props;

        return (
            <div className={`personal-data-error ${props.className ?? ''}`}>
                <ErrorBoundary>
                    <div className="img-wrapper">
                        <InfoMessage infoMessage={t(['You are not authorized to modify your personal data.', 'pages.privacy.personalDataError.error'])} text={t(['Access your settings in the mobile application to get authorization.', 'pages.privacy.personalDataError.errorText'])} />
                        <img src={invalidToken} alt={t(['Instruction to find settings button on mobile application', 'pages.privacy.personalDataError.instructions'])} />
                    </div>
                </ErrorBoundary>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

//language=SCSS
PersonalDataErrorPage = Styled(PersonalDataErrorPage)`
& {
    position: relative;
    padding: 80px 0 0 0;
    text-align: center;
    border-bottom: solid 1px ${colorPalette.thirdBackground};
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 50px;
        width: 100%;
        background: linear-gradient(0deg, rgba(0,0,0,0.08), rgba(0,0,0,0.0));
    }
    
    .info-message {
        font-size: 1.1rem;
    }
    
    > .img-wrapper {
        margin: 0 auto;
        width: 600px;
    }
    
    img {
        margin-top: 25px;
    }
}
`;

export default withTranslation()(PersonalDataErrorPage);
